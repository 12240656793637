<template>
  <div>
    <v-card
      class="elevation-0"
      :disabled="disabled"
    >
      <v-toolbar
        v-if="searchForm || geo"
        dense
        floating
        class="map-toolbar"
      >
        <v-form
          v-if="searchForm"
          @submit.prevent="search"
        >
          <v-text-field
            v-model="query"
            hide-details
            :prepend-icon="mdiMagnify"
            single-line
            placeholder="Search"
          ></v-text-field>
        </v-form>

        <v-btn
          v-if="geo"
          icon
          @click="requestLocation"
        >
          <v-icon>{{ mdiCrosshairsGps }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div
        :id="containerID"
        :style="containerCSS"
      ></div>
      <div>
        <v-snackbar
          v-model="notificaciones.snackbar"
          :bottom="notificaciones.y === 'bottom'"
          :color="notificaciones.color"
          :left="notificaciones.x === 'left'"
          :multi-line="notificaciones.mode === 'multi-line'"
          :right="notificaciones.x === 'right'"
          :timeout="notificaciones.timeout"
          :top="notificaciones.y === 'top'"
          :vertical="notificaciones.mode === 'vertical'"
        >
          {{ notificaciones.text }}
          <template v-slot:action="{ attrs }">
            <v-btn
              dark
              text
              v-bind="attrs"
              @click="notificaciones.snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mdiCrosshairsGps, mdiMagnify } from '@mdi/js'

const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js')

export default {
  model: {
    prop: 'datetime',
    event: 'input',
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    geo: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchForm: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: '500px',
    },
    initialLocation: {
      type: Array,
      default: () => [-79.88845390663774, -2.1901432864314643],
    },
    ubicacionLocal: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: 'orange',
    },
    apiKey: {
      type: String,
      required: true,
    },
    mapStyle: {
      type: String,
      default: 'mapbox://styles/mapbox/outdoors-v11',
    },
    containerID: {
      type: String,
      default: 'map',
    },
  },
  data() {
    return {
      map: null,
      query: '',
      location: [],
      mdiCrosshairsGps,
      mdiMagnify,
      notificaciones: {
        snackbar: false,
        color: '',
        mode: '',
        text: '',
        timeout: 3000,
        x: null,
        y: 'top',
      },
    }
  },
  computed: {
    containerCSS() {
      return {
        width: '100%',
        height: '400px',
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.initMap()
    }, 2000)
  },
  methods: {
    async initMap() {
      // mapboxgl.accessToken = this.apiKey
      await Promise.resolve()
      let ubicacionInicial = this.initialLocation
      if (this.ubicacionLocal.length > 0) {
        ubicacionInicial = this.ubicacionLocal
      }

      // Create map object
      this.map = new mapboxgl.Map({
        container: this.containerID,
        style: this.mapStyle,
        center: ubicacionInicial,
        zoom: this.ubicacionLocal.length > 0 ? 15 : 10,
        attributionControl: false,
        maxBounds: [
          [-80.9677654691, -4.95912851321],
          [-75.2337227037, 1.3809237736],
        ],
      })

      // Adds basic zoom and rotate control
      this.map.addControl(new mapboxgl.NavigationControl())

      // Add Click Listener
      this.map.on('click', e => {
        this.setLocation(e.lngLat)
      })
      this.addMapMarker(ubicacionInicial)
    },
    removeMapMarkers() {
      const oldMarker = document.querySelector('.mapboxgl-marker')
      if (oldMarker) {
        oldMarker.parentElement.removeChild(oldMarker)
      }
    },
    addMapMarker(lngLat) {
      new mapboxgl.Marker({ color: this.color })
        .setLngLat(lngLat)
        .addTo(this.map)
    },
    setLocationCoordinates(lngLat) {
      this.location = [lngLat.lng, lngLat.lat]
    },
    requestLocation() {
      // Request to get the user's current location
      window.navigator.geolocation.getCurrentPosition(position => {
        // get the latitude and longitude returned
        const lat = position.coords.latitude
        const lng = position.coords.longitude

        // set location data
        this.setLocation({ lng, lat })
        this.map.flyTo({ center: [lng, lat], zoom: 15 })
      })
    },
    setLocation(lngLat) {
      this.removeMapMarkers()
      this.addMapMarker(lngLat)
      this.setLocationCoordinates(lngLat)
      this.$emit('input', this.location)
    },
    async search() {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.query}.json?access_token=${this.apiKey}&bbox=-80.9677654691,-4.95912851321,-75.2337227037,1.3809237736`,
      )
      this.query = ''
      const responseBody = await response.json()

      // Check we have at least 1 result
      if (responseBody.features.length === 0) {
        this.notificaciones.color = 'warning'
        this.notificaciones.text = 'No se encontró ningun resultado'
        this.notificaciones.snackbar = true

        return null
      }

      const [lng, lat] = responseBody.features[0].center
      this.setLocation({ lng, lat })
      this.map.flyTo({ center: [lng, lat], zoom: 15 })
    },
  },
}
</script>

<style lang="scss" scoped>
  #map {
    height: 100vh;
  }
  .map-toolbar {
    margin-bottom: -60px;
    margin-left: 8px;
    z-index: 10;
  }
</style>
