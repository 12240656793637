<template>
  <v-row class="match-height">
    <v-col cols="12">
      <Breadcrumbs :items="items" />
    </v-col>
    <v-col cols="12">
      <v-form
        ref="form"
        class="multi-col-validation"
        lazy-validation
      >
        <v-card>
          <v-card-title>{{ formTitulo }}</v-card-title>
          <v-tabs
            v-model="tab"
            show-arrows
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.id"
              @click="keyComponnet += 1"
            >
              <v-icon
                v-if="tab.error"
                color="error"
              >
                {{ tab.icons }}
              </v-icon>
              <span class="mx-2">{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <!-- tabs item -->
          <v-tabs-items v-model="tab">
            <v-tab-item eager>
              <v-row class="pa-3 mt-2">
                <!-- Imagen -->
                <v-col
                  cols="12"
                  md="4"
                  class="d-flex justify-center align-center"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card
                      max-width="300"
                      max-height="300"
                      height="300"
                      width="300"
                      class="mx-auto"
                    >
                      <v-container class="pa-1">
                        <v-img
                          v-if="isUrl(obj.imageUrl)"
                          :src="obj.imageUrl"
                          height="300"
                          class="text-right"
                          @error="offLink = true"
                        >
                          <template #placeholder>
                            <v-row
                              class="fill-height ma-0 grey"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                v-if="offLink === false"
                                size="70"
                                indeterminate
                                color="grey lighten-5"
                              >
                                <!-- <v-icon size="50" dark>{{
                                                icono_imagen
                                              }}</v-icon>-->
                              </v-progress-circular>
                              <v-icon
                                v-else
                                size="50"
                                dark
                              >
                                {{
                                  icono_link
                                }}
                              </v-icon>
                            </v-row>
                          </template>

                          <v-expand-transition>
                            <div
                              v-if="hover && isEditing"
                              class="
                                d-flex
                                transition-fast-in-fast-out
                                primary
                                darken-2
                                v-card--reveal
                                white--text
                              "
                              style="height: 100%"
                              @click="onPickFile"
                            >
                              <div>
                                <div
                                  class="
                                    d-flex
                                    justify-center
                                    white--text
                                    display-1
                                  "
                                >
                                  Click
                                </div>
                              </div>
                            </div>
                          </v-expand-transition>
                        </v-img>
                        <v-img
                          v-else
                          :src="imageUrlDefault"
                          max-width="300"
                          max-height="300"
                          height="300"
                          width="300"
                          class="text-right"
                          @error="offLink = true"
                        >
                          <template #placeholder>
                            <v-row
                              class="fill-height ma-0 grey"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                v-if="offLink === false"
                                size="70"
                                indeterminate
                                color="grey lighten-5"
                              >
                                <!-- <v-icon size="50" dark>{{
                                                icono_imagen
                                              }}</v-icon>-->
                              </v-progress-circular>
                              <v-icon
                                v-else
                                size="50"
                                dark
                              >
                                {{
                                  icono_link
                                }}
                              </v-icon>
                            </v-row>
                          </template>

                          <v-expand-transition>
                            <div
                              v-if="hover && isEditing"
                              class="
                                d-flex
                                transition-fast-in-fast-out
                                primary
                                darken-2
                                v-card--reveal
                                white--text
                              "
                              style="height: 100%"
                              @click="onPickFile"
                            >
                              <div>
                                <div
                                  class="
                                    d-flex
                                    justify-center
                                    white--text
                                    display-1
                                  "
                                >
                                  Click
                                </div>
                              </div>
                            </div>
                          </v-expand-transition>
                        </v-img>
                        <input
                          id="fileInput"
                          ref="refFileInput"
                          type="file"
                          accept="image/*"
                          style="display: none"
                          :disabled="!isEditing"
                          :rules="[
                            (v) => !!v || 'El campo Foto es obligatorio',
                          ]"
                          @change="onFilePicked"
                        />
                      </v-container>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-row>
                    <!-- Nombre -->
                    <v-col cols="12">
                      <v-text-field
                        ref="refName"
                        v-model="obj.name"
                        label="Nombre"
                        outlined
                        dense
                        placeholder="Nombre"
                        error-count="2"
                        :error="campos.name.error"
                        :error-messages="errorMsgName"
                        counter="100"
                        maxlength="100"
                        :rules="[
                          (v) => !!v || 'El campo Nombre es obligatorio',
                          (v) => v.length <= 100 || 'Este campo solo puede tener 100 caracteres',
                        ]"
                      >
                        <template #label>
                          <app-label
                            field="Nombre"
                            required="true"
                          />
                        </template>
                      </v-text-field>
                    </v-col>
                    <!-- Descripcion -->
                    <v-col cols="12">
                      <v-textarea
                        ref="refDescripcion"
                        v-model="obj.description"
                        outlined
                        dense
                        height="230"
                        placeholder="Acerca de / Descripción"
                        error-count="2"
                        :error="campos.description.error"
                        :error-messages="errorMsgDescription"
                        counter="250"
                        maxlength="250"
                        :rules="[
                          (v) => !!v || 'El campo Descripción es obligatorio',
                          (v) => v.length <= 250 || 'Este campo solo puede tener 250 caracteres'
                        ]"
                      >
                        <template #label>
                          <app-label
                            field="Descripción"
                            required="true"
                          />
                        </template>
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- URL -->
                <v-col
                  cols="6"
                  md="6"
                >
                  <v-text-field
                    v-model="obj.link"
                    label="Url"
                    outlined
                    dense
                    placeholder="Url"
                    :rules="[
                      (v) => (v.length === 0 || /^(ftp|http|https|chrome|:\/\/|\.|@){2,}(localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\S*:\w*@)*([a-zA-Z]|(\d{1,3}|\.){7}){1,}(\w|\.{2,}|\.[a-zA-Z]{2,3}|\/|\?|&|:\d|@|=|\/|\(.*\)|#|-|%)*$/gum.test(v)) || 'El campo Url no es válida',
                    ]"
                    error-count="2"
                    :error="campos.link.error"
                    :error-messages="errorMsgLink"
                  >
                  </v-text-field>
                </v-col>
                <!-- Nombre URL -->
                <v-col
                  cols="6"
                  md="6"
                >
                  <v-text-field
                    v-model="obj.nameLink"
                    label="Nombre de la URL"
                    outlined
                    dense
                    placeholder="Nombre de la URL"
                    error-count="2"
                    :error="campos.nameLink.error"
                    :error-messages="errorMsgNameLink"
                    counter="30"
                    maxlength="30"
                    :rules="[
                      (v) => v.length <= 100 || 'Este campo solo puede tener 30 caracteres',
                    ]"
                  >
                    Nombre de la URL
                  </v-text-field>
                </v-col>
                <!-- Fecha de publicacion -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        ref="refPublishAt"
                        v-model="obj.publishAt"
                        label="Fecha de publicación"
                        placeholder="Fecha de publicación"
                        persistent-hint
                        :prepend-inner-icon="icons.mdiCalendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        :rules="[
                          (v) => !!v || 'El campo Fecha de publicación es obligatorio',
                        ]"
                        v-on="on"
                      >
                        <template #label>
                          <app-label
                            field="Fecha de publicación"
                            required="true"
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="obj.publishAt"
                      no-title
                      :min="minDatePublishAt"
                      @input="menu3 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Fecha de caducidad -->
                <v-col
                  v-if="false"
                  cols="12"
                  md="6"
                >
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        ref="refExpireAt"
                        v-model="obj.expireAt"
                        persistent-hint
                        :prepend-inner-icon="icons.mdiCalendar"
                        placeholder="Fecha de caducidad"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        :rules="[
                          (v) => !!v || 'El campo Fecha de caducidad es obligatorio',
                        ]"
                        v-on="on"
                      >
                        <template #label>
                          <app-label
                            field="Fecha de expiración"
                            required="true"
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="obj.expireAt"
                      no-title
                      :min="minDateExpireAt"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Proyección -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="obj.projection"
                    label="Proyección"
                    outlined
                    dense
                    placeholder="Proyección"
                    error-count="2"
                    :error="campos.projection.error"
                    :error-messages="errorMsgProjection"
                    counter="100"
                    maxlength="100"
                    :rules="[
                      (v) => v.length <= 100 || 'Este campo solo puede tener 100 caracteres',
                    ]"
                  >
                    Proyección
                  </v-text-field>
                </v-col>
                <!-- Estado -->
                <v-col
                  v-if="false"
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="obj.status"
                    :items="status"
                    item-value="id"
                    item-text="name"
                    label="Estado"
                    placeholder="Estado"
                    outlined
                    dense
                    required
                  ></v-select>
                </v-col>

                <!--Inaugurado-->
                <v-col cols="6">
                  <v-switch
                    v-model="obj.inaugurated"
                    label="Inaugurado"
                  ></v-switch>
                </v-col>

                <!-- Horario -->
                <!-- <v-col cols="12">
                  <v-text-field
                    ref="refAttentionSchedule"
                    v-model="obj.attentionSchedule"
                    label="Horario"
                    outlined
                    dense
                    placeholder="Horario"
                    error-count="2"
                    :error="campos.attentionSchedule.error"
                    :error-messages="errorMsgattentionSchedule"
                    counter="100"
                    maxlength="100"
                    :rules="[
                      (v) => !!v || 'El campo Horario es obligatorio',
                      (v) => v.length <= 100 || 'Este campo solo puede tener 100 caracteres'
                    ]"
                  >
                    <template #label>
                      <app-label
                        field="Horario"
                        required="true"
                      />
                    </template>
                  </v-text-field>
                </v-col> -->
              </v-row>
            </v-tab-item>
            <!-- Mapa -->
            <v-tab-item eager>
              <v-row class="pa-3 mt-2">
                <!-- Nombre de Referencia -->
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    ref="refreferenceAddress"
                    v-model="obj.referenceAddress"
                    label="Dirección de referencia"
                    outlined
                    dense
                    placeholder="Dirección de referencia"
                    :rules="[
                      (v) => !!v || 'El campo Dirección de referencia es obligatorio',
                    ]"
                    :error="campos.referenceAddress.error"
                    :error-messages="errorMsgreferenceAddress"
                  >
                    <template #label>
                      <app-label
                        field="Dirección de referencia"
                        required="true"
                      />
                    </template>
                  </v-text-field>
                </v-col>
                <!-- Longitud -->
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    ref="refLongitud"
                    v-model="obj.longitude = obj.location[0]"
                    label="Longitud"
                    outlined
                    readonly
                    dense
                    placeholder="Longitud"
                    :rules="[
                      (v) => !!v || 'El campo Longitud es obligatorio',
                    ]"
                  >
                    <template #label>
                      <app-label
                        field="Longitud"
                        required="true"
                      />
                    </template>
                  </v-text-field>
                </v-col>

                <!-- Latitud -->
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    ref="refLatitud"
                    v-model="obj.latitude = obj.location[1]"
                    label="Latitud"
                    outlined
                    readonly
                    dense
                    placeholder="Latitud"
                    :rules="[
                      (v) => !!v || 'El campo Latitud es obligatorio',
                    ]"
                  >
                    <template #label>
                      <app-label
                        field="Latitud"
                        required="true"
                      />
                    </template>
                  </v-text-field>
                </v-col>

                <!-- Mapa -->
                <v-col
                  cols="12"
                >
                  <LocationPicker
                    :key="keyComponnet"
                    ref="locationPicker"
                    v-model="obj.location"
                    :api-key="apiKey"
                    :geo="geo"
                    :search-form="searchForm"
                    :color="color"
                    :initial-location="ubicacionInicial"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions
            v-if="true"
            class="mt-5"
          >
            <v-row>
              <!-- Botones de acción -->
              <v-col cols="12">
                <v-btn
                  color="primary"
                  :loading="loadingSaveForm"
                  type="button"
                  @click.prevent="saveForm"
                >
                  Enviar
                </v-btn>
                <v-btn
                  type="reset"
                  outlined
                  class="mx-2"
                  @click="cancelarForm"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-snackbar
          v-model="notificaciones.snackbar"
          :bottom="notificaciones.y === 'bottom'"
          :color="notificaciones.color"
          :left="notificaciones.x === 'left'"
          :multi-line="notificaciones.mode === 'multi-line'"
          :right="notificaciones.x === 'right'"
          :timeout="notificaciones.timeout"
          :top="notificaciones.y === 'top'"
          :vertical="notificaciones.mode === 'vertical'"
        >
          {{ notificaciones.text }}
          <template v-slot:action="{ attrs }">
            <v-btn
              dark
              text
              v-bind="attrs"
              @click="notificaciones.snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiCalendar, mdiAlertOctagonOutline,
} from '@mdi/js'
import { ref, reactive, computed } from '@vue/composition-api'
import moment from 'moment'
import { useRouter } from '@/utils'
import LocationPicker from '@/components/LocationPicker.vue'
import HTTP from '@/utils/axios/axios-config-base'
import AppLabel from '@/components/field/Label'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  components: {
    Breadcrumbs,
    LocationPicker,
    AppLabel,
  },
  setup() {
    const apiKey = 'pk.eyJ1Ijoiam9zZTg5MDgyMyIsImEiOiJja3plZjIzczMwZjEwMm9vYmdpam81b2Y4In0.4BCud0O62PlRyUwXYVaXCQ'
    const tab = ref(0)
    const activeTabsError = ref(false)
    const keyComponnet = ref(0)

    // tabs
    const tabs = reactive([
      {
        id: '1', title: 'Datos Generales', icons: mdiAlertOctagonOutline, error: false,
      },
      {
        id: '2', title: 'Ubicación', icons: mdiAlertOctagonOutline, error: false,
      },

      // { id: '2', title: 'Requisitos / Tiempo' },
    ])
    const status = ref([
      { id: 'approved', name: 'Aprobado', disabled: false },
      { id: 'pending', name: 'Pendiente', disabled: false },
      { id: 'Denied', name: 'Denegado', disabled: true },
      { id: 'cancelled', name: 'Cancelado', disabled: true },
    ])
    const modalidades = [
      { id: 'Mixed', name: 'Presencial y Virtual' },
      { id: 'FaceToFace', name: 'Presencial' },
      { id: 'Virtual', name: 'Virtual' },
    ]
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })
    const { router } = useRouter()
    const formTitulo = ref('Crear Nueva Obra')
    const offLink = ref(false)
    const isEditing = ref(true)
    const imageUrlDefault = ref(require('@/assets/images/default-img.png'))
    const icono_link = ref(require('@/assets/images/default-img.png'))
    const refFileInput = ref(null)
    const form = ref(null)
    const refreferenceAddress = ref(null)
    const refName = ref(null)
    const refDescripcion = ref(null)
    const link = ref('')
    const nameLink = ref('')
    const projection = ref('')
    const inaugurated = ref(false)
    const refPublishAt = ref(null)
    const refExpireAt = ref(null)
    const refLongitud = ref(null)
    const refLatitud = ref(null)
    const currentUser = ref(null)

    // const refAttentionSchedule = ref(null)
    const formDisabled = ref(false)
    const minDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    const obj = reactive({
      name: '',
      description: '',
      link: '',
      nameLink: '',
      projection: '',
      inaugurated: false,
      publishAt: '',
      expireAt: '',
      imageUrl: '',
      referenceAddress: '',
      latitude: '',
      longitude: '',

      // attentionSchedule: '',
      location: [],
    })
    const campos = reactive({
      name: {
        error: false,
        mensajesError: [],
      },
      description: {
        error: false,
        mensajesError: [],
      },
      nameLink: {
        error: false,
        mensajesError: [],
      },
      link: {
        error: false,
        mensajesError: [],
      },
      projection: {
        error: false,
        mensajesError: [],
      },
      inaugurated: {
        error: false,
        mensajesError: [],
      },
      publishAt: {
        error: false,
        mensajesError: [],
      },
      expireAt: {
        error: false,
        mensajesError: [],
      },
      imageUrl: {
        error: false,
        mensajesError: [],
      },
      referenceAddress: {
        error: false,
        mensajesError: [],
      },

      // attentionSchedule: {
      //   error: false,
      //   mensajesError: [],
      // },
      latitude: {
        error: false,
        mensajesError: [],
      },
      longitude: {
        error: false,
        mensajesError: [],
      },
    })
    const items = reactive([
      {
        text: 'Inicio',
        disabled: false,
        href: '/',
      },
      {
        text: 'Obras',
        disabled: false,
        href: '/obras',
      },
      {
        text: 'Crear Obras',
        disabled: true,
        href: '/obras/crear-obras',
      },
    ])
    const loadingSaveForm = ref(false)
    const geo = ref(true)
    const searchForm = ref(true)
    const height = ref(500)
    const color = ref('#FFA500')
    const ubicacionInicial = ref([-79.88245227233337, -2.202246732389483])

    // Methods
    const minDatePublishAt = computed(() => moment((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)).toISOString())
    const minDateExpireAt = computed(() => moment(obj.eventDate).toISOString())

    // Errores
    const errorMsgName = computed(() => (campos.name.error ? campos.name.mensajesError : []))
    const errorMsgDescription = computed(() => (campos.description.error ? campos.description.mensajesError : []))
    const errorMsgLink = computed(() => (campos.link.error ? campos.link.mensajesError : []))
    const errorMsgNameLink = computed(() => (campos.nameLink.error ? campos.nameLink.mensajesError : []))
    const errorMsgProjection = computed(() => (campos.projection.error ? campos.projection.mensajesError : []))
    const errorMsgInaugurated = computed(() => (campos.inaugurated.error ? campos.inaugurated.mensajesError : []))
    const errorMsgPublishAt = computed(() => (campos.publishAt.error ? campos.publishAt.mensajesError : []))
    const errorMsgExpireAt = computed(() => (campos.expireAt.error ? campos.expireAt.mensajesError : []))
    const errorMsgImageUrl = computed(() => (campos.imageUrl.error ? campos.imageUrl.mensajesError : []))
    const errorMsgreferenceAddress = computed(() => (campos.referenceAddress.error ? campos.referenceAddress.mensajesError : []))
    const errorMsgLongitude = computed(() => (campos.longitude.error ? campos.longitude.mensajesError : []))
    const errorMsgLatitude = computed(() => (campos.latitude.error ? campos.latitude.mensajesError : []))

    // const errorMsgattentionSchedule = computed(() => (campos.attentionSchedule.error ? campos.attentionSchedule.mensajesError : []))

    const disableByModality = () => {
      if (obj.modality === 'FaceToFace' || !obj.modality) {
        return true
      }

      return false
    }

    const requiredByModality = v => {
      let temp = true
      if (v && (obj.modality === 'Mixed' || obj.modality === 'Virtual')) {
        temp = true
      } else if (obj.modality === 'presencial') {
        temp = true
      } else {
        temp = false
      }

      return temp
    }

    const isUrl = s => {
      if (s === '') return false
      const regexp = /(ftp|http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/

      return regexp.test(s)
    }

    const isUrlValid = s => {
      if (s === '') return true
      const regexp = /(ftp|http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/

      return regexp.test(s)
    }

    const isValidName = s => {
      if (s === '') return true
      const regexp = /^[A-Za-zÀ-ÿ]+([\\ ]{0,1}[A-Za-z0-9À-ÿ]+)*$/

      return regexp.test(s)
    }

    const getCurrentUser = async () => {
      const URL = `${HTTP.defaults.baseURL}identity-api/user/me`
      try {
        const resp = await HTTP.get(URL)
        if (resp.status === 200) {
          currentUser.value = resp.data.areaId
        }
      } catch (error) {
        console.log('Ha ocurrido un problema al ejecutar la petición')
      }
    }
    getCurrentUser()

    return {
      menu: ref(false),
      menu2: ref(false),
      menu3: ref(false),
      apiKey,
      tab,
      tabs,
      activeTabsError,
      form,
      refreferenceAddress,
      refName,
      refDescripcion,
      refLongitud,
      refLatitud,
      link,
      nameLink,
      projection,
      inaugurated,
      refPublishAt,
      refExpireAt,
      currentUser,

      // refAttentionSchedule,
      formDisabled,
      items,
      formTitulo,
      status,
      offLink,
      isEditing,
      refFileInput,
      imageUrlDefault,
      icono_link,
      modalidades,
      loadingSaveForm,
      obj,
      campos,
      geo,
      searchForm,
      height,
      color,
      ubicacionInicial,
      notificaciones,
      keyComponnet,
      minDate,
      minDatePublishAt,
      minDateExpireAt,
      isValidName,

      // iconos
      icons: {
        mdiCalendar,
        mdiAlertOctagonOutline,
      },
      isUrl,
      isUrlValid,

      // Errores
      errorMsgName,
      errorMsgDescription,
      errorMsgLink,
      errorMsgNameLink,
      errorMsgProjection,
      errorMsgInaugurated,
      errorMsgPublishAt,
      errorMsgExpireAt,
      errorMsgImageUrl,
      errorMsgreferenceAddress,

      // errorMsgattentionSchedule,
      errorMsgLatitude,
      errorMsgLongitude,
      disableByModality,
      requiredByModality,

      esHoraValida: v => (v.split(':')[0] >= 0 && v.split(':')[0] < 24) || 'El campo hora debe estar entre 0 y 23',
      changeDate: () => {
        refPublishAt.value.reset()
        refExpireAt.value.reset()
      },
      onPickFile: () => {
        // Cuando se hace click en el boton, activa el file input
        refFileInput.value.click()
      },
      onFilePicked: event => {
        const { files } = event.target
        const filename = files[0] ? files[0].name : ''
        const size = files[0] ? files[0].size : ''
        const ext = files[0] ? files[0].type : ''
        if (files[0]) {
          // si no tiene una extension muestro un error
          if (filename && filename.lastIndexOf('.') <= 0) {
            notificaciones.snackbar = true
            notificaciones.color = 'warning'
            notificaciones.text = 'Por favor selecciona un archivo valido'
          } else if (size && size >= 1000000) {
            notificaciones.snackbar = true
            notificaciones.color = 'warning'
            notificaciones.text = 'Máximo 1 fichero. Límite de 1 MB'
          } else if (ext && ext !== 'image/jpeg' && ext !== 'image/jpg' && ext !== 'image/png') {
            notificaciones.snackbar = true
            notificaciones.color = 'warning'
            notificaciones.text = `Los archivos de tipo ${ext} no estan permitidos. Tipos permitidos: png jpg jpeg.`
          } else {
            const fileReader = new FileReader()
            if (fileReader) {
              fileReader.addEventListener('load', () => {
                imageUrlDefault.value = fileReader.result
              })
              fileReader.readAsDataURL(files[0])
              obj.imageUrl = files[0]
            }
          }
        }
      },
      cancelarForm: () => {
        router.push({ name: 'obras' })
      },

      saveForm: async () => {
        if (obj.imageUrl === '') {
          notificaciones.color = 'error'
          notificaciones.text = 'El campo Imagen es obligatorio'
          notificaciones.snackbar = true
        } else if (form.value.validate()) {
          loadingSaveForm.value = true
          const objToSave = {
          // obj
            name: obj.name,
            description: obj.description,
            link: obj.link,
            nameLink: obj.nameLink,
            projection: obj.projection,
            inaugurated: obj.inaugurated,
            publishAt: obj.publishAt,
            expireAt: '2080-05-05',
            referenceAddress: obj.referenceAddress,
            status: 'pending',
            alertType: 'notification',
            areaId: currentUser.value,

            // attentionSchedule: obj.attentionSchedule,
            latitude: obj.latitude ? obj.latitude.toString() : '',
            longitude: obj.longitude ? obj.longitude.toString() : '',
          }
          try {
            const URL = `${HTTP.defaults.baseURL}publicworks-api/publicwork`
            const resp = await HTTP.post(URL, objToSave)
            if (resp.status === 201) {
              loadingSaveForm.value = false
              if (typeof obj.imageUrl === 'object') {
                try {
                  const URLImg = `${HTTP.defaults.baseURL}publicworks-api/publicwork/${resp.data.id}/images`
                  const formData = new FormData()
                  formData.append('file', obj.imageUrl)
                  const imgResp = await HTTP.post(URLImg, formData)
                } catch (error) {
                }
              }
              router.push({
                name: 'obras',
                params: {
                  notificaciones: {
                    snackbar: true,
                    text: 'El registro ha sido creado satisfactoriamente',
                    color: 'success',
                  },
                },
              })
            }
          } catch (error) {
            if (error.response.data.errors.Name) {
              for (let index = 0; index < error.response.data.errors.Name.length; index += 1) {
                campos.name.mensajesError.push(error.response.data.errors.Name[index])
              }
              campos.name.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.Description) {
              for (let index = 0; index < error.response.data.errors.Description.length; index += 1) {
                campos.description.mensajesError.push(error.response.data.errors.Description[index])
              }
              campos.description.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.Link) {
              for (let index = 0; index < error.response.data.errors.Link.length; index += 1) {
                campos.link.mensajesError.push(error.response.data.errors.Link[index])
              }
              campos.link.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.NameLink) {
              for (let index = 0; index < error.response.data.errors.NameLink.length; index += 1) {
                campos.nameLink.mensajesError.push(error.response.data.errors.NameLink[index])
              }
              campos.nameLink.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.Projection) {
              for (let index = 0; index < error.response.data.errors.Projection.length; index += 1) {
                campos.projection.mensajesError.push(error.response.data.errors.Projection[index])
              }
              campos.projection.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.Inaugurated) {
              for (let index = 0; index < error.response.data.errors.Inaugurated.length; index += 1) {
                campos.inaugurated.mensajesError.push(error.response.data.errors.Inaugurated[index])
              }
              campos.inaugurated.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.ExpireAt) {
              for (let index = 0; index < error.response.data.errors.ExpireAt.length; index += 1) {
                campos.expireAt.mensajesError.push(error.response.data.errors.ExpireAt[index])
              }
              campos.expireAt.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.referenceAddress) {
              for (let index = 0; index < error.response.data.errors.referenceAddress.length; index += 1) {
                campos.referenceAddress.mensajesError.push(error.response.data.errors.referenceAddress[index])
              }
              campos.referenceAddress.error = true
              tabs[1].error = true
            }

            // if (error.response.data.errors.attentionSchedule) {
            //   for (let index = 0; index < error.response.data.errors.attentionSchedule.length; index += 1) {
            //     campos.attentionSchedule.mensajesError.push(error.response.data.errors.attentionSchedule[index])
            //   }
            //   campos.attentionSchedule.error = true
            //   tabs[1].error = true
            // }
            if (error.response.data.errors.latitude) {
              for (let index = 0; index < error.response.data.errors.latitude.length; index += 1) {
                campos.latitude.mensajesError.push(error.response.data.errors.latitude[index])
              }
              campos.latitude.error = true
              tabs[0].error = true
            }
            if (error.response.data.errors.longitude) {
              for (let index = 0; index < error.response.data.errors.longitude.length; index += 1) {
                campos.longitude.mensajesError.push(error.response.data.errors.longitude[index])
              }
              campos.longitude.error = true
              tabs[0].error = true
            }
            loadingSaveForm.value = false
          }
        } else {
          // En caso de que alguno de los campos requeridos no son validos, muestro su correspondiente tabs en error
          if (
            !refName.value.validate()
            || !refDescripcion.value.validate()
            || !refPublishAt.value.validate()

          // || !refAttentionSchedule.value.validate()
          ) {
            tabs[0].error = true
            if (tab.value === 1) {
              notificaciones.color = 'error'
              notificaciones.text = 'Complete los campos obligatorios en el Tab de Datos Generales'
              notificaciones.snackbar = true
            }
          } else {
            tabs[0].error = false
          }
          if (
            !refreferenceAddress.value.validate()
            || !refLongitud.value.validate()
            || !refLatitud.value.validate()
          ) {
            if (tab.value === 0) {
              notificaciones.color = 'error'
              notificaciones.text = 'Complete los campos obligatorios en el Tab de Ubicación'
              notificaciones.snackbar = true
            }
            tabs[1].error = true
          } else {
            tabs[1].error = false
          }
        }
      },

      isValidNameSession: value => {
        // Esta funcion es para validar un campo que no es obligatorio,
        // pero en caso de tener valor que solo admita hasta 200 caracteres
        if (value) {
          if (value.length > 200) {
            return false
          }

          return true
        }

        return true
      },

      isValidLength: (value, number) => {
        // Esta funcion es para validar un campo que no es obligatorio,
        // pero en caso de tener valor que solo admita hasta 200 caracteres
        if (value) {
          if (value.length <= number) {
            return true
          }

          return false
        }

        return true
      },
    }
  },
}
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}
</style>
